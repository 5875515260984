<template>
  <form
    novalidate
    @submit.prevent="submit"
  >
    <notice
      :on-beige-background="onBeigeBackground"
      name="usable"
      type="confirmation"
    />

    <div v-if="!isAddCoApplicantLead">
      <div class="row">
        <form-input
          field="education"
          group="applicant"
        />
        <form-input
          field="citizenship"
          group="applicant"
        />
      </div>

      <div class="row">
        <form-input
          field="maritalStatus"
          group="applicant"
        />
        <form-input
          field="children"
          group="applicant"
        />
      </div>

      <zg-transition-fade @after-transition="validate">
        <div
          v-if="(
            (mainApplicantHasPartner && !livesWithCoApplicant) ||
            (mainApplicantHasPartner && !hasCoApplicant)
          )"
          class="input-box input-box--with-arrow input-box--with-arrow-left input-box--column"
        >
          <div class="row">
            <form-input
              field="spousesMonthlyIncome"
              group="applicant"
            />
            <form-input
              field="spousesMonthlyCost"
              group="applicant"
            />
          </div>
        </div>
      </zg-transition-fade>

      <div
        v-if="showApplicantMilitaryService"
        class="row row--full-width"
      >
        <form-input
          field="militaryService"
          group="applicant"
        />
      </div>
    </div>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Co Applicant                                                                           -->
    <!-- -------------------------------------------------------------------------------------- -->

    <div v-if="hasCoApplicant || isAddCoApplicantLead">
      <h3>
        {{ title(true, 'personalDetails') }}
      </h3>

      <div class="row">
        <form-input
          field="education"
          group="coApplicant"
        />
        <div>
          <form-input
            field="citizenship"
            group="coApplicant"
          />
        </div>
      </div>

      <div v-if="!livesWithCoApplicant">
        <div class="row">
          <form-input
            field="maritalStatus"
            group="coApplicant"
          />
          <form-input
            field="children"
            group="coApplicant"
          />
        </div>

        <zg-transition-fade @after-transition="validate">
          <div
            v-if="coApplicantHasPartner"
            class="row"
          >
            <form-input
              field="spousesMonthlyIncome"
              group="coApplicant"
            />
            <form-input
              field="spousesMonthlyCost"
              group="coApplicant"
            />
          </div>
        </zg-transition-fade>
      </div>

      <div
        v-if="showCoApplicantMilitaryService"
        class="row row--full-width"
      >
        <form-input
          field="militaryService"
          group="coApplicant"
        />
      </div>
    </div>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- CreditCards                                                                            -->
    <!-- -------------------------------------------------------------------------------------- -->

    <div v-if="!isAddCoApplicantLead">
      <h5>
        {{ content.titles.creditCard }}
      </h5>

      <div class="row row--full-width">
        <form-input
          field="hasCreditCards"
          group="debts"
        />
      </div>

      <zg-transition-block>
        <debts
          v-if="hasCreditCards"
          debt-type="creditCard"
        />
      </zg-transition-block>

      <!-- -------------------------------------------------------------------------------------- -->
      <!-- OtherLoans                                                                             -->
      <!-- -------------------------------------------------------------------------------------- -->

      <h5>
        {{ content.titles.otherLoans }}
      </h5>

      <div class="row row--full-width">
        <form-input
          field="hasOtherLoans"
          group="debts"
        />
      </div>

      <zg-transition-block>
        <debts
          v-if="hasOtherLoans"
          debt-type="otherLoan"
        />
      </zg-transition-block>
    </div>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Bank                                                                                   -->
    <!-- -------------------------------------------------------------------------------------- -->

    <div v-if="!isAddCoApplicantLead">
      <h4>
        {{ content.titles.bankDetails }}
      </h4>

      <div class="row">
        <form-input
          field="bank"
          group="applicant"
        />
        <form-input
          field="ibanNumber"
          group="applicant"
        />
      </div>
    </div>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- CoApplicant                                                                            -->
    <!-- -------------------------------------------------------------------------------------- -->

    <div v-if="hasCoApplicant || isAddCoApplicantLead">
      <h4>
        {{ title(true, 'economicalDetails', 'bankDetails') }}
      </h4>

      <!-- -------------------------------------------------------------------------------------- -->
      <!-- Bank coApplicant                                                                       -->
      <!-- -------------------------------------------------------------------------------------- -->

      <div class="row">
        <form-input
          field="bank"
          group="coApplicant"
        />
        <form-input
          field="ibanNumber"
          group="coApplicant"
        />
      </div>
    </div>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Terms                                                                                  -->
    <!-- -------------------------------------------------------------------------------------- -->

    <div
      v-if="!isAddCoApplicantLead"
      class="row row--full-width"
    >
      <form-input
        field="creditDataClub"
        group="applicant"
        @input="openCreditDataClubModal"
      />
    </div>

    <div
      v-if="isAddCoApplicantLead"
      class="row row--full-width"
    >
      <form-input
        field="creditDataClub"
        group="coApplicant"
        @input="openCreditDataClubModal"
      />
    </div>
  </form>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { applicantTitle } from '../../../../../utils'
  import FormInput from '../../../../elements/_shared/misc/FormInput'
  import Notice from '../../../../elements/_shared/misc/Notice'
  import Debts from '../../../../elements/cl/debts/Debts'

  export default {
    name: 'Step3FI',
    components: {
      Notice,
      Debts,
      FormInput,
      ZgTransitionBlock: () => import(/* webpackChunkName: 'zc/zg-transition-block' */
        '@zc/components/ZgTransitionBlock/ZgTransitionBlock'),
      ZgTransitionFade: () => import(/* webpackChunkName: 'zc/zg-transition-fade' */
        '@zc/components/ZgTransitionFade/ZgTransitionFade')
    },
    props: {
      onBeigeBackground: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('experiments', ['getExperiments']),
      ...mapGetters('form', ['getForm']),
      ...mapGetters('cl/formAlternatives', ['getFormAlternatives']),
      content () {
        return this.getContent?.application?.singleStep?.economicalDetails ?? {}
      },
      mainApplicantHasPartner () {
        const applicantMaritalStatus = this.getForm?.applicant?.maritalStatus?.value
        return this.isMarried(applicantMaritalStatus)
      },
      coApplicantHasPartner () {
        const applicantMaritalStatus = this.getForm?.coApplicant?.maritalStatus?.value
        return this.isMarried(applicantMaritalStatus)
      },
      isAddCoApplicantLead () {
        return this.getForm?.global?.leadType?.value === 'addCoApplicant'
      },
      hasCoApplicant () {
        return this.getForm?.global?.hasCoApplicant?.value
      },
      livesWithCoApplicant () {
        return !!this.getForm?.applicant?.livesWithCoApplicant?.value
      },
      creditDataClub () {
        return this.getForm?.applicant?.creditDataClub?.value
      },
      hasCreditCards () {
        return this.getForm?.debts?.hasCreditCards?.value
      },
      hasOtherLoans () {
        return this.getForm?.debts?.hasOtherLoans?.value
      },
      showApplicantMilitaryService () {
        return this.gender(this.getForm?.applicant?.ssn?.value) === 'm' || false
      },
      showCoApplicantMilitaryService () {
        return this.gender(this.getForm?.coApplicant?.ssn?.value) === 'm' || false
      }
    },
    async mounted () {
      await this.viewTracking({ label: 'economicalDetails' })

      this.$emit('loaded')
    },
    methods: {
      ...mapActions('messages', ['setModalMessage']),
      ...mapActions('form', ['validateFields']),
      ...mapActions('cl/analytics', ['viewTracking']),
      submit () {
        this.$emit('submit')
      },
      title (coApplicant = false, fieldSection, title = null) {
        return applicantTitle({
          market: this.MARKET,
          form: this.getForm,
          content: {
            ...this.getContent,
            ...this.content,
            title: this.getContent?.application?.singleStep?.[fieldSection]?.titles?.[title] ?? this.getContent?.application?.singleStep?.[fieldSection]?.title
          },
          coApplicant
        })
      },
      isMarried (applicantMaritalStatus) {
        return [this.MARITAL_STATUSES.LIVE_TOGETHER, this.MARITAL_STATUSES.MARRIED]
          .includes(applicantMaritalStatus)
      },
      gender (ssn) {
        const genderCharIndex = 9
        return ssn && (ssn[genderCharIndex] % 2 === 0
          ? 'f'
          : 'm')
      },
      async validate () {
        await this.validateFields()
      },
      async openCreditDataClubModal () {
        if (!this.creditDataClub) {
          await this.setModalMessage({ group: 'creditDataClub', name: 'noOfferWarning', show: true })
        }
      }
    }
  }
</script>
