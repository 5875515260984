<template>
  <form
    novalidate
    @submit.prevent="submit"
  >
    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Applicant                                                                              -->
    <!-- -------------------------------------------------------------------------------------- -->

    <div v-if="!isAddCoApplicantLead">
      <notice
        v-if="hasPrefilledFields"
        :on-beige-background="onBeigeBackground"
        name="prefill"
      />

      <div class="row">
        <form-input
          field="ssn"
          group="applicant"
        />

        <div>
          <form-input
            field="email"
            group="applicant"
          />

          <verify
            field="email"
            group="applicant"
          />
        </div>
      </div>

      <div class="row">
        <form-input
          field="phone"
          group="applicant"
        />
        <form-input
          field="employmentType"
          group="applicant"
          @input="prefillEmployment('applicant')"
        />
      </div>

      <zg-transition-block @after-transition="visibleEmploymentFields('applicant')">
        <div
          v-if="showEmploymentFields('applicant')"
          class="input-box input-box--with-arrow input-box--with-arrow-right"
        >
          <div class="row">
            <form-input
              field="monthlyIncome"
              group="applicant"
            />
            <form-input
              field="monthlyNetIncome"
              group="applicant"
            />
          </div>

          <div class="row">
            <form-input
              field="otherIncomeAmount"
              group="applicant"
            />
          </div>

          <zg-transition-fade @after-transition="validate">
            <div
              v-if="hasEmployment('applicant')"
              class="row"
            >
              <form-input
                field="employer"
                group="applicant"
              />

              <form-input
                field="employerIndustry"
                group="applicant"
              />
            </div>
          </zg-transition-fade>

          <zg-transition-fade @after-transition="validate">
            <div
              v-if="hasEmployment('applicant')"
              class="row"
            >
              <form-input
                field="socialEconomicStatus"
                group="applicant"
              />
              <form-input
                field="profession"
                group="applicant"
              />
            </div>
          </zg-transition-fade>

          <div class="row">
            <form-input
              field="employedSince"
              group="applicant"
            />

            <zg-transition-fade @after-transition="validate">
              <form-input
                v-if="monthsEmployedLessThanTwelve(employedSince)"
                field="previousEmployer"
                group="applicant"
              />
            </zg-transition-fade>
          </div>

          <zg-transition-block @after-transition="validate">
            <div
              v-if="monthsEmployedLessThanTwelve(employedSince)"
              class="row"
            >
              <form-input
                field="previousEmployedSince"
                group="applicant"
              />
              <form-input
                field="previousEmployedTo"
                group="applicant"
              />
            </div>
          </zg-transition-block>

          <zg-transition-fade @after-transition="validate">
            <div
              v-if="timeBasedEmployment('applicant')"
              class="row"
            >
              <form-input
                field="employedTo"
                group="applicant"
              />
            </div>
          </zg-transition-fade>
        </div>
      </zg-transition-block>

      <div class="row">
        <form-input
          field="residenceType"
          group="applicant"
        />
        <form-input
          field="rent"
          group="applicant"
        />
      </div>

      <zg-transition-fade @after-transition="visibleResidenceFields('applicant')">
        <div
          v-if="showResidenceFields('applicant')"
          class="input-box input-box--with-arrow input-box--with-arrow-left"
        >
          <div class="row">
            <form-input
              class="align-self-bottom"
              field="residenceOwnershipType"
              group="applicant"
            />

            <form-input
              field="livedSince"
              group="applicant"
            />
          </div>

          <zg-transition-fade @after-transition="validate">
            <div v-if="ownLiving('applicant')">
              <div class="row row--full-width">
                <form-input
                  field="hasMortgage"
                  group="applicant"
                />
              </div>

              <zg-transition-fade @after-transition="validate">
                <div v-if="hasMortgage('applicant')">
                  <div class="row">
                    <form-input
                      field="mortgageBank"
                      group="applicant"
                    />

                    <zg-transition-fade @after-transition="validate">
                      <form-input
                        v-if="livesWithCoApplicant"
                        field="mortgageHolder"
                        group="applicant"
                      />
                    </zg-transition-fade>
                  </div>

                  <div class="row">
                    <form-input
                      field="mortgageAmount"
                      group="applicant"
                    />
                    <form-input
                      field="mortgageMonthlyCost"
                      group="applicant"
                    />
                  </div>
                </div>
              </zg-transition-fade>
            </div>
          </zg-transition-fade>
        </div>
      </zg-transition-fade>

      <div class="row">
        <form-input
          field="regularMonthlyExpenses"
          group="applicant"
        />

        <form-input
          field="loanPurpose"
          group="global"
        />
      </div>

      <div class="row row--full-width">
        <form-input
          field="pep"
          group="applicant"
        />
      </div>
    </div>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Add Co Applicant button                                                                -->
    <!-- -------------------------------------------------------------------------------------- -->

    <div v-if="!getApplicationEvents.createApplicationWithCoApplicant && !isAddCoApplicantLead">
      <add-co-applicant-button-variation />
    </div>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Co Applicant                                                                           -->
    <!-- -------------------------------------------------------------------------------------- -->

    <zg-transition-block @after-transition="validate">
      <div v-if="hasCoApplicant || isAddCoApplicantLead">
        <div
          v-if="!isAddCoApplicantLead"
          class="row row--full-width"
        >
          <form-input
            field="livesWithCoApplicant"
            group="applicant"
            @input="updateMaritalStatus"
          />
        </div>

        <div class="row">
          <form-input
            field="ssn"
            group="coApplicant"
          />

          <div>
            <form-input
              field="email"
              group="coApplicant"
            />

            <verify
              field="email"
              group="coApplicant"
            />
          </div>
        </div>

        <div class="row">
          <form-input
            field="phone"
            group="coApplicant"
          />
          <form-input
            field="employmentType"
            group="coApplicant"
            @input="prefillEmployment('coApplicant')"
          />
        </div>

        <zg-transition-block @after-transition="visibleEmploymentFields('coApplicant')">
          <div
            v-if="showEmploymentFields('coApplicant')"
            class="input-box input-box--with-arrow input-box--with-arrow-right"
          >
            <div class="row">
              <form-input
                field="monthlyIncome"
                group="coApplicant"
              />
              <form-input
                field="monthlyNetIncome"
                group="coApplicant"
              />
            </div>

            <div class="row">
              <form-input
                field="otherIncomeAmount"
                group="coApplicant"
              />
            </div>

            <zg-transition-fade @after-transition="validate">
              <div
                v-if="hasEmployment('coApplicant')"
                class="row"
              >
                <form-input
                  field="employer"
                  group="coApplicant"
                />

                <form-input
                  field="employerIndustry"
                  group="coApplicant"
                />
              </div>
            </zg-transition-fade>

            <zg-transition-fade @after-transition="validate">
              <div
                v-if="hasEmployment('coApplicant')"
                class="row"
              >
                <form-input
                  field="socialEconomicStatus"
                  group="coApplicant"
                />
                <form-input
                  field="profession"
                  group="coApplicant"
                />
              </div>
            </zg-transition-fade>

            <div class="row">
              <form-input
                field="employedSince"
                group="coApplicant"
              />

              <zg-transition-fade @after-transition="validate">
                <form-input
                  v-if="monthsEmployedLessThanTwelve(hasCoApplicant ? coEmployedSince : employedSince)"
                  field="previousEmployer"
                  group="coApplicant"
                />
              </zg-transition-fade>
            </div>

            <zg-transition-block @after-transition="validate">
              <div
                v-if="monthsEmployedLessThanTwelve(hasCoApplicant ? coEmployedSince : employedSince)"
                class="row"
              >
                <form-input
                  field="previousEmployedSince"
                  group="coApplicant"
                />
                <form-input
                  field="previousEmployedTo"
                  group="coApplicant"
                />
              </div>
            </zg-transition-block>

            <zg-transition-fade @after-transition="validate">
              <div
                v-if="timeBasedEmployment('coApplicant')"
                class="row"
              >
                <form-input
                  field="employedTo"
                  group="coApplicant"
                />
              </div>
            </zg-transition-fade>
          </div>
        </zg-transition-block>

        <zg-transition-fade @after-transition="validate">
          <div
            v-if="!livesWithCoApplicant"
            class="row"
          >
            <form-input
              field="residenceType"
              group="coApplicant"
            />
            <form-input
              field="rent"
              group="coApplicant"
            />
          </div>
        </zg-transition-fade>

        <zg-transition-fade @after-transition="visibleResidenceFields('coApplicant')">
          <div
            v-if="showResidenceFields('coApplicant') && !livesWithCoApplicant"
            class="input-box input-box--with-arrow input-box--with-arrow-left"
          >
            <div class="row">
              <form-input
                field="residenceOwnershipType"
                group="coApplicant"
              />
              <form-input
                field="livedSince"
                group="coApplicant"
              />
            </div>

            <zg-transition-fade @after-transition="validate">
              <div v-if="ownLiving('coApplicant')">
                <div class="row row--full-width">
                  <form-input
                    field="hasMortgage"
                    group="coApplicant"
                  />
                </div>

                <zg-transition-fade @after-transition="validate">
                  <div v-if="hasMortgage('coApplicant')">
                    <div class="row">
                      <form-input
                        field="mortgageBank"
                        group="coApplicant"
                      />

                      <zg-transition-fade @after-transition="validate">
                        <form-input
                          v-if="coApplicantHasPartner"
                          field="mortgageHolder"
                          group="coApplicant"
                        />
                      </zg-transition-fade>
                    </div>

                    <div class="row">
                      <form-input
                        field="mortgageAmount"
                        group="coApplicant"
                      />
                      <form-input
                        field="mortgageMonthlyCost"
                        group="coApplicant"
                      />
                    </div>
                  </div>
                </zg-transition-fade>
              </div>
            </zg-transition-fade>
          </div>
        </zg-transition-fade>

        <div class="row">
          <form-input
            field="regularMonthlyExpenses"
            group="coApplicant"
          />
          <form-input
            v-if="livesWithCoApplicant"
            field="rent"
            group="coApplicant"
          />
        </div>

        <div class="row row--full-width">
          <form-input
            field="pep"
            group="coApplicant"
          />
        </div>
      </div>
    </zg-transition-block>

    <!-- -------------------------------------------------------------------------------------- -->
    <!-- Terms                                                                                  -->
    <!-- -------------------------------------------------------------------------------------- -->

    <zg-transition-block @after-transition="validate">
      <div
        v-if="!getApplicationEvents.createApplication && !isAddCoApplicantLead"
        class="row row--full-width"
      >
        <form-input
          field="digitalCommunication"
          group="applicant"
        />
      </div>
    </zg-transition-block>

    <zg-transition-fade @after-transition="validate">
      <div
        v-if="isAddCoApplicantLead"
        class="row row--full-width"
      >
        <form-input
          field="digitalCommunication"
          group="coApplicant"
        />
      </div>
    </zg-transition-fade>

    <zg-transition-block @after-transition="validate">
      <div
        v-if="!getApplicationEvents.createApplication && !isAddCoApplicantLead"
        class="row row--full-width"
      >
        <form-input
          field="userAgreement"
          group="applicant"
        />
      </div>
    </zg-transition-block>

    <div
      v-if="isAddCoApplicantLead"
      class="row row--full-width"
    >
      <form-input
        field="userAgreement"
        group="coApplicant"
      />
    </div>
  </form>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import FormInput from '../../../../elements/_shared/misc/FormInput'
  import Notice from '../../../../elements/_shared/misc/Notice'
  import AddCoApplicantButtonVariation from '../../../../elements/cl/misc/AddCoApplicantVariation'
  import Verify from '../../../../elements/_shared/misc/Verify'

  export default {
    name: 'Step2FI',
    components: {
      Verify,
      Notice,
      AddCoApplicantButtonVariation,
      FormInput,
      ZgTransitionBlock: () => import(/* webpackChunkName: 'zc/zg-transition-block' */
        '@zc/components/ZgTransitionBlock/ZgTransitionBlock'),
      ZgTransitionFade: () => import(/* webpackChunkName: 'zc/zg-transition-fade' */
        '@zc/components/ZgTransitionFade/ZgTransitionFade')
    },
    props: {
      onBeigeBackground: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      hasPrefilledFields: false
    }),
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('experiments', ['getExperiments']),
      ...mapGetters('form', ['getForm']),
      ...mapGetters('cl/application', ['getApplicationEvents']),
      ...mapGetters('cl/formAlternatives', ['getFormAlternatives']),
      content () {
        return this.getContent?.application?.singleStep?.loanDetails ?? {}
      },
      coApplicantHasPartner () {
        const applicantMaritalStatus = this.getForm?.coApplicant?.maritalStatus?.value
        return this.isMarried(applicantMaritalStatus)
      },
      hasCoApplicant () {
        return this.getForm?.global?.hasCoApplicant?.value
      },
      isAddCoApplicantLead () {
        return this.getForm?.global?.leadType?.value === 'addCoApplicant'
      },
      livesWithCoApplicant () {
        return !!this.getForm?.applicant?.livesWithCoApplicant?.value
      },
      employedSince () {
        return this.getForm?.applicant?.employedSince?.value
      },
      coEmployedSince () {
        return this.getForm?.coApplicant?.employedSince?.value
      }
    },
    async mounted () {
      await this.viewTracking({ label: 'personalData' })
      await this.fetchFormAlternatives()
      await this.addCustomerData()

      this.$emit('loaded')
    },
    methods: {
      ...mapActions('experiments', ['activateExperiment']),
      ...mapActions('form', ['setField', 'validateFields', 'resetField', 'focusField', 'blurField']),
      ...mapActions('cl/application', ['fetchPrefillData']),
      ...mapActions('cl/analytics', ['viewTracking']),
      ...mapActions('cl/formAlternatives', ['fetchFormAlternatives']),
      submit () {
        this.$emit('submit')
      },
      async updateMaritalStatus () {
        if (!this.livesWithCoApplicant) return
        const maritalStatus = this.getForm?.applicant?.maritalStatus?.value

        if (this.SINGLE_MARITAL_STATUSES.includes(maritalStatus)) {
          await this.resetField({
            group: 'applicant',
            field: 'maritalStatus'
          })
        }
      },
      isMarried (applicantMaritalStatus) {
        return [this.MARITAL_STATUSES.LIVE_TOGETHER, this.MARITAL_STATUSES.MARRIED]
          .includes(applicantMaritalStatus)
      },
      async validate () {
        await this.validateFields()
      },
      async addCustomerData () {
        if (this.getForm?.global?.leadType?.value) return

        if (this.getApplicationEvents.fetchedCustomerData) {
          this.hasPrefilledFields = true
        } else {
          this.hasPrefilledFields = await this.fetchPrefillData()
        }
      },
      async visibleResidenceFields (applicant) {
        await this.validate()

        if (this.showResidenceFields(applicant)) {
          const label = applicant === 'coApplicant'
            ? 'co-applicant-housing'
            : 'personal-data-housing'
          await this.viewTracking({ label })
        }
      },
      showResidenceFields (applicant = 'applicant') {
        return this.getForm?.[applicant]?.residenceType?.value
      },
      ownLiving (applicant = 'applicant') {
        const residenceType = this.getForm?.[applicant]?.residenceType?.value
        return [this.RESIDENCE_TYPES.TENANT, this.RESIDENCE_TYPES.OWNED_APARTMENT]
          .includes(residenceType)
      },
      hasMortgage (applicant = 'applicant') {
        return this.getForm?.[applicant]?.hasMortgage?.value
      },
      async visibleEmploymentFields (applicant) {
        await this.validate()

        if (this.showEmploymentFields(applicant)) {
          const label = applicant === 'coApplicant'
            ? 'co-applicant-employment'
            : 'personal-data-employment'
          await this.viewTracking({ label })
        }
      },
      showEmploymentFields (applicant) {
        return (
          this.getForm?.[applicant]?.employmentType?.value ||
          this.getForm?.[applicant]?.monthlyIncome?.value ||
          this.getForm?.[applicant]?.employer?.value ||
          this.getForm?.[applicant]?.employedSince?.value
        )
      },
      employmentType (applicant) {
        const employmentType = this.getForm?.[applicant]?.employmentType?.value || ''
        return parseInt(employmentType, 10)
      },
      hasEmployment (applicant) {
        if (!this.employmentType(applicant)) return

        return this.HAS_EMPLOYMENT.includes(this.employmentType(applicant))
      },
      timeBasedEmployment (applicant) {
        if (!this.employmentType(applicant)) return

        return (
          this.getForm?.[applicant]?.employedSince?.valid &&
          this.TIME_BASED_EMPLOYMENT.includes(this.employmentType(applicant))
        )
      },
      monthsEmployedLessThanTwelve (employedSince) {
        if (!employedSince || !employedSince.year || !employedSince.month) return

        const nowDate = new Date()
        const pastDate = new Date(nowDate.getFullYear() - 1, nowDate.getMonth(), nowDate.getDate())
        const employedDate = new Date(employedSince.year, employedSince.month)

        return (employedDate.getTime() > pastDate.getTime())
      },
      async prefillEmployment (group) {
        const employmentType = this.getForm?.[group]?.employmentType?.value || 0
        let employer = null
        let socialEconomicalStatus = null

        await this.resetField({ group, field: 'employer' })
        await this.resetField({ group, field: 'socialEconomicStatus' })
        await this.resetField({ group, field: 'profession' })

        switch (employmentType) {
          case this.EMPLOYMENT_TYPES.RETIRED:
            employer = this.getFormAlternatives.employmentTypes.find(item => item.value === this.EMPLOYMENT_TYPES.RETIRED).label
            socialEconomicalStatus = this.SOCIAL_ECONOMIC_STATUSES.RETIRED
            break
          case this.EMPLOYMENT_TYPES.SELF_EMPLOYED:
            socialEconomicalStatus = this.SOCIAL_ECONOMIC_STATUSES.OWN_COMPANY
            break
          case this.EMPLOYMENT_TYPES.UNEMPLOYED:
            socialEconomicalStatus = this.SOCIAL_ECONOMIC_STATUSES.OTHER
            break
          case this.EMPLOYMENT_TYPES.STUDENT:
            socialEconomicalStatus = this.SOCIAL_ECONOMIC_STATUSES.STUDENT
            break
          case this.EMPLOYMENT_TYPES.PARENTAL_LEAVE:
            socialEconomicalStatus = this.SOCIAL_ECONOMIC_STATUSES.OTHER
            break
          case this.EMPLOYMENT_TYPES.FARMER:
            socialEconomicalStatus = this.SOCIAL_ECONOMIC_STATUSES.FARMER
            break
        }

        if (employer) {
          await this.setField({ group, field: 'employer', value: employer, valid: true, touched: true })
          await this.setField({ group, field: 'profession', value: employer, valid: true, touched: true })
        }

        if (socialEconomicalStatus) {
          await this.setField({ group, field: 'socialEconomicStatus', value: socialEconomicalStatus, valid: true, touched: true })
          await this.blurField({ group, field: 'socialEconomicStatus' })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .align-self-bottom {
    align-self: flex-end;
  }
</style>
