var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add-co-applicant-variation" }, [
    _c("label", { attrs: { for: "global-showCoApplicant" } }, [
      _c("span", [
        _c("span", [_vm._v(_vm._s(_vm.content.title))]),
        _vm._v(" "),
        _c("span", { staticClass: "is-required" }, [_vm._v("*")]),
      ]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.content.text))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        class: [
          "radio",
          {
            "radio--fi": _vm.FINLAND,
          },
        ],
      },
      [
        _c("zg-radio", {
          attrs: {
            error: _vm.error,
            invalid: _vm.invalid,
            options: _vm.content.options,
            value: _vm.fieldData.value,
            "full-width": "",
            name: "global-showCoApplicant",
            "no-margin": "",
          },
          on: {
            input: function ($event) {
              return _vm.toggle($event)
            },
          },
          model: {
            value: _vm.val,
            callback: function ($$v) {
              _vm.val = $$v
            },
            expression: "val",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }