var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      !_vm.isAddCoApplicantLead
        ? _c(
            "div",
            { staticClass: "applicant" },
            [
              _c(
                "zg-transition-block",
                { on: { "after-transition": _vm.validate } },
                [
                  _vm.showRefinanceChoice
                    ? _c(
                        "div",
                        { staticClass: "row row--full-width" },
                        [
                          _c("form-input", {
                            attrs: { field: "refinance", group: "global" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "zg-transition-block",
                { on: { "after-transition": _vm.validate } },
                [
                  !_vm.showRefinanceChoice || _vm.hasSelectedRefinance
                    ? _c("div", { staticClass: "input-box" }, [
                        _c("p", { staticClass: "refinance" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.content.refinancePreAmount) +
                              "\n          "
                          ),
                          _c("strong", [_vm._v(_vm._s(_vm.loanAmount))]),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.content.refinancePostAmount) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("form-input", {
                              attrs: { field: "totalDebt", group: "debts" },
                              on: { blur: _vm.updateRefinanceValues },
                            }),
                            _vm._v(" "),
                            _c("form-input", {
                              staticClass: "s-margin-top",
                              attrs: {
                                field: "totalDebtMonthlyCost",
                                group: "debts",
                              },
                              on: { blur: _vm.showHighDebtMonthlyCostWarning },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.hasPrefilledFields
                ? _c("notice", {
                    attrs: {
                      "on-beige-background": _vm.onBeigeBackground,
                      name: "prefill",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "zg-transition-block",
                { on: { "after-transition": _vm.visiblePurposeField } },
                [
                  _vm.showPurpose
                    ? _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("form-input", {
                            attrs: { field: "loanPurpose", group: "global" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("form-input", {
                    attrs: { field: "ssn", group: "applicant" },
                  }),
                  _vm._v(" "),
                  _c("form-input", {
                    attrs: { field: "phone", group: "applicant" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "div",
                    [
                      _c("form-input", {
                        attrs: { field: "email", group: "applicant" },
                      }),
                      _vm._v(" "),
                      _c("verify", {
                        attrs: { field: "email", group: "applicant" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("form-input", {
                    attrs: { field: "children", group: "applicant" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  [
                    _c("form-input", {
                      attrs: { field: "employmentType", group: "applicant" },
                    }),
                    _vm._v(" "),
                    _c(
                      "zg-transition-block",
                      {
                        on: {
                          "after-transition": function ($event) {
                            return _vm.visibleEmploymentFields("applicant")
                          },
                        },
                      },
                      [
                        _vm.showEmploymentFields("applicant")
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "input-box input-box--with-arrow input-box--column",
                              },
                              [
                                _c("form-input", {
                                  attrs: {
                                    field: "monthlyIncome",
                                    group: "applicant",
                                  },
                                }),
                                _vm._v(" "),
                                _vm.hasEmployment("applicant")
                                  ? _c("form-input", {
                                      attrs: {
                                        field: "employer",
                                        group: "applicant",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("form-input", {
                                  attrs: {
                                    field: "employedSince",
                                    group: "applicant",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "zg-transition-fade",
                                  [
                                    _vm.timeBasedEmployment("applicant")
                                      ? _c("form-input", {
                                          attrs: {
                                            field: "employedTo",
                                            group: "applicant",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("form-input", {
                      attrs: { field: "maritalStatus", group: "applicant" },
                    }),
                    _vm._v(" "),
                    _c(
                      "zg-transition-block",
                      {
                        on: {
                          "after-transition": function ($event) {
                            return _vm.visibleResidenceFields("applicant")
                          },
                        },
                      },
                      [
                        _vm.showResidenceFields("applicant")
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "input-box input-box--with-arrow input-box--column",
                              },
                              [
                                _c("form-input", {
                                  attrs: {
                                    field: "residenceType",
                                    group: "applicant",
                                  },
                                  on: { input: _vm.updateResidenceType },
                                }),
                                _vm._v(" "),
                                _c("form-input", {
                                  attrs: { field: "rent", group: "applicant" },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          class: [
            "co-applicant-wrapper",
            {
              "co-applicant-wrapper--border": _vm.isDetailCoApplicant,
            },
          ],
        },
        [
          !_vm.getApplicationEvents.createApplicationWithCoApplicant
            ? _c(
                "div",
                { key: "add-co-applicant" },
                [
                  _vm.isSimpleCoApplicant
                    ? _c("add-co-applicant-radio-simple", {
                        key: "add-co-applicant-radio-simple",
                      })
                    : _vm.isDetailCoApplicant
                    ? _c("add-co-applicant-radio-detail", {
                        key: "add-co-applicant-radio-detail",
                      })
                    : _c("add-co-applicant-button", {
                        key: "add-co-applicant-button",
                      }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "zg-transition-block",
            { on: { "after-transition": _vm.validate } },
            [
              _vm.hasCoApplicant || _vm.isAddCoApplicantLead
                ? _c(
                    "div",
                    { staticClass: "co-applicant" },
                    [
                      _c(
                        "div",
                        { staticClass: "row row--full-width" },
                        [
                          _c("form-input", {
                            attrs: {
                              field: "livesWithCoApplicant",
                              group: "applicant",
                            },
                            on: { input: _vm.updateMaritalStatus },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("form-input", {
                            attrs: { field: "ssn", group: "coApplicant" },
                          }),
                          _vm._v(" "),
                          _c("form-input", {
                            attrs: { field: "phone", group: "coApplicant" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("zg-transition-block", [
                        !_vm.livesWithCoApplicant
                          ? _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("form-input", {
                                      attrs: {
                                        field: "email",
                                        group: "coApplicant",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("verify", {
                                      attrs: {
                                        field: "email",
                                        group: "coApplicant",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("form-input", {
                                  attrs: {
                                    field: "children",
                                    group: "coApplicant",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c(
                            "zg-transition-block",
                            { on: { "after-transition": _vm.validate } },
                            [
                              _vm.livesWithCoApplicant
                                ? _c(
                                    "div",
                                    [
                                      _c("form-input", {
                                        attrs: {
                                          field: "email",
                                          group: "coApplicant",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("form-input", {
                                        attrs: {
                                          field: "rent",
                                          group: "coApplicant",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _c("form-input", {
                                attrs: {
                                  field: "employmentType",
                                  group: "coApplicant",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "zg-transition-block",
                                {
                                  on: {
                                    "after-transition": function ($event) {
                                      return _vm.visibleEmploymentFields(
                                        "coApplicant"
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm.showEmploymentFields("coApplicant")
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "input-box input-box--with-arrow",
                                        },
                                        [
                                          _c("form-input", {
                                            attrs: {
                                              field: "monthlyIncome",
                                              group: "coApplicant",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.hasEmployment("coApplicant")
                                            ? _c("form-input", {
                                                attrs: {
                                                  field: "employer",
                                                  group: "coApplicant",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c("form-input", {
                                            attrs: {
                                              field: "employedSince",
                                              group: "coApplicant",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "zg-transition-fade",
                                            [
                                              _vm.timeBasedEmployment(
                                                "coApplicant"
                                              )
                                                ? _c("form-input", {
                                                    attrs: {
                                                      field: "employedTo",
                                                      group: "coApplicant",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "zg-transition-block",
                            { on: { "after-transition": _vm.validate } },
                            [
                              !_vm.livesWithCoApplicant
                                ? _c(
                                    "div",
                                    [
                                      _c("form-input", {
                                        attrs: {
                                          field: "maritalStatus",
                                          group: "coApplicant",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "zg-transition-block",
                                        {
                                          on: {
                                            "after-transition": function (
                                              $event
                                            ) {
                                              return _vm.visibleResidenceFields(
                                                "coApplicant"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm.showResidenceFields("coApplicant")
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "input-box input-box--with-arrow",
                                                },
                                                [
                                                  _c("form-input", {
                                                    attrs: {
                                                      field: "residenceType",
                                                      group: "coApplicant",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("form-input", {
                                                    attrs: {
                                                      field: "rent",
                                                      group: "coApplicant",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.getApplicationEvents.createApplication && !_vm.isAddCoApplicantLead
        ? _c(
            "div",
            { staticClass: "row row--full-width" },
            [
              _c("form-input", {
                attrs: { field: "digitalCommunication", group: "applicant" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAddCoApplicantLead
        ? _c(
            "div",
            { staticClass: "row row--full-width" },
            [
              _c("form-input", {
                attrs: { field: "digitalCommunication", group: "coApplicant" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.getApplicationEvents.createApplication && !_vm.isAddCoApplicantLead
        ? _c(
            "div",
            { staticClass: "row row--full-width" },
            [
              _c("form-input", {
                attrs: { field: "userAgreement", group: "applicant" },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAddCoApplicantLead
        ? _c(
            "div",
            { staticClass: "row row--full-width" },
            [
              _c("form-input", {
                attrs: { field: "userAgreement", group: "coApplicant" },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }