var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [_c("calculator", { on: { interacted: _vm.next } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }