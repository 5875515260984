<template>
  <div />
</template>

<script>
  export default {
    name: 'Step1NO',
    mounted () {
      this.$emit('loaded')
    }
  }
</script>
