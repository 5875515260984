var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h3", [_vm._v("\n    " + _vm._s(_vm.content.title) + "\n  ")]),
    _vm._v(" "),
    _c("p", { domProps: { innerHTML: _vm._s(_vm.text) } }),
    _vm._v(" "),
    _c(
      "a",
      { attrs: { href: _vm.url, target: "_blank" } },
      [
        _c(
          "zg-button",
          {
            attrs: {
              icons: { right: "arrow-forward" },
              size: "xlarge",
              type: "button",
            },
            on: { click: _vm.login },
          },
          [_vm._v("\n      " + _vm._s(_vm.content.login) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }