<template>
  <div>
    <thank-you-step />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import ThankYouStep from '../_shared/ThankYouStep'

  export default {
    name: 'Step3SE',
    components: {
      ThankYouStep
    },
    async mounted () {
      await this.viewTracking({ label: 'thankYou' })

      this.$emit('loaded')
    },
    methods: {
      ...mapActions('cl/analytics', ['viewTracking'])
    }
  }
</script>
