<template>
  <div>
    <h3>
      {{ content.title }}
    </h3>

    <p v-html="text" />

    <a
      :href="url"
      target="_blank"
    >
      <zg-button
        :icons="{ right: 'arrow-forward' }"
        size="xlarge"
        type="button"
        @click="login"
      >
        {{ content.login }}
      </zg-button>
    </a>
  </div>
</template>

<script>
  import { replaceBrandVars } from '@ocp-zmarta/zmarta-cl'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'ThankYouStep',
    components: {
      ZgButton: () => import(/* webpackChunkName: 'zc/zg-button' */
        '@zc/components/ZgButton/ZgButton')
    },
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('translations', ['getLocale', 'getMyAccountOffersUrl']),
      ...mapGetters('form', ['getForm']),
      content () {
        return this.getContent?.application?.singleStep?.thankYou ?? {}
      },
      text () {
        const text = this.WHITE_LABEL
          ? this.content?.text?.whiteLabel
          : this.content?.text?.default

        return replaceBrandVars({
          brand: this.BRAND,
          market: this.MARKET,
          vertical: this.VERTICAL,
          locale: this.getLocale,
          content: text,
          form: this.getForm
        })
      },
      url () {
        return this.getMyAccountOffersUrl
      }
    },
    methods: {
      ...mapActions('cl/analytics', ['buttonTracking']),
      async login () {
        await this.buttonTracking({
          step: 'thank-you-step',
          label: 'thank-you-login'
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  a {
    display: inline-block;
    border: none;
    margin: 0 0 rhythm(xsmall);
  }
</style>
