var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      _c("notice", {
        attrs: {
          "on-beige-background": _vm.onBeigeBackground,
          name: "usable",
          type: "confirmation",
        },
      }),
      _vm._v(" "),
      !_vm.isAddCoApplicantLead
        ? _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("form-input", {
                    attrs: { field: "education", group: "applicant" },
                  }),
                  _vm._v(" "),
                  _c("form-input", {
                    attrs: { field: "citizenship", group: "applicant" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("form-input", {
                    attrs: { field: "maritalStatus", group: "applicant" },
                  }),
                  _vm._v(" "),
                  _c("form-input", {
                    attrs: { field: "children", group: "applicant" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "zg-transition-fade",
                { on: { "after-transition": _vm.validate } },
                [
                  (_vm.mainApplicantHasPartner && !_vm.livesWithCoApplicant) ||
                  (_vm.mainApplicantHasPartner && !_vm.hasCoApplicant)
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "input-box input-box--with-arrow input-box--with-arrow-left input-box--column",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c("form-input", {
                                attrs: {
                                  field: "spousesMonthlyIncome",
                                  group: "applicant",
                                },
                              }),
                              _vm._v(" "),
                              _c("form-input", {
                                attrs: {
                                  field: "spousesMonthlyCost",
                                  group: "applicant",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _vm.showApplicantMilitaryService
                ? _c(
                    "div",
                    { staticClass: "row row--full-width" },
                    [
                      _c("form-input", {
                        attrs: { field: "militaryService", group: "applicant" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hasCoApplicant || _vm.isAddCoApplicantLead
        ? _c("div", [
            _c("h3", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.title(true, "personalDetails")) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("form-input", {
                  attrs: { field: "education", group: "coApplicant" },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("form-input", {
                      attrs: { field: "citizenship", group: "coApplicant" },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            !_vm.livesWithCoApplicant
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("form-input", {
                          attrs: {
                            field: "maritalStatus",
                            group: "coApplicant",
                          },
                        }),
                        _vm._v(" "),
                        _c("form-input", {
                          attrs: { field: "children", group: "coApplicant" },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "zg-transition-fade",
                      { on: { "after-transition": _vm.validate } },
                      [
                        _vm.coApplicantHasPartner
                          ? _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("form-input", {
                                  attrs: {
                                    field: "spousesMonthlyIncome",
                                    group: "coApplicant",
                                  },
                                }),
                                _vm._v(" "),
                                _c("form-input", {
                                  attrs: {
                                    field: "spousesMonthlyCost",
                                    group: "coApplicant",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showCoApplicantMilitaryService
              ? _c(
                  "div",
                  { staticClass: "row row--full-width" },
                  [
                    _c("form-input", {
                      attrs: { field: "militaryService", group: "coApplicant" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAddCoApplicantLead
        ? _c(
            "div",
            [
              _c("h5", [
                _vm._v(
                  "\n      " + _vm._s(_vm.content.titles.creditCard) + "\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row row--full-width" },
                [
                  _c("form-input", {
                    attrs: { field: "hasCreditCards", group: "debts" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "zg-transition-block",
                [
                  _vm.hasCreditCards
                    ? _c("debts", { attrs: { "debt-type": "creditCard" } })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("h5", [
                _vm._v(
                  "\n      " + _vm._s(_vm.content.titles.otherLoans) + "\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row row--full-width" },
                [
                  _c("form-input", {
                    attrs: { field: "hasOtherLoans", group: "debts" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "zg-transition-block",
                [
                  _vm.hasOtherLoans
                    ? _c("debts", { attrs: { "debt-type": "otherLoan" } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAddCoApplicantLead
        ? _c("div", [
            _c("h4", [
              _vm._v(
                "\n      " + _vm._s(_vm.content.titles.bankDetails) + "\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("form-input", {
                  attrs: { field: "bank", group: "applicant" },
                }),
                _vm._v(" "),
                _c("form-input", {
                  attrs: { field: "ibanNumber", group: "applicant" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasCoApplicant || _vm.isAddCoApplicantLead
        ? _c("div", [
            _c("h4", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.title(true, "economicalDetails", "bankDetails")) +
                  "\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("form-input", {
                  attrs: { field: "bank", group: "coApplicant" },
                }),
                _vm._v(" "),
                _c("form-input", {
                  attrs: { field: "ibanNumber", group: "coApplicant" },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.isAddCoApplicantLead
        ? _c(
            "div",
            { staticClass: "row row--full-width" },
            [
              _c("form-input", {
                attrs: { field: "creditDataClub", group: "applicant" },
                on: { input: _vm.openCreditDataClubModal },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isAddCoApplicantLead
        ? _c(
            "div",
            { staticClass: "row row--full-width" },
            [
              _c("form-input", {
                attrs: { field: "creditDataClub", group: "coApplicant" },
                on: { input: _vm.openCreditDataClubModal },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }