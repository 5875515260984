var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      attrs: { novalidate: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        },
      },
    },
    [
      !_vm.isAddCoApplicantLead
        ? _c(
            "div",
            [
              _vm.hasPrefilledFields
                ? _c("notice", {
                    attrs: {
                      "on-beige-background": _vm.onBeigeBackground,
                      name: "prefill",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("form-input", {
                    attrs: { field: "ssn", group: "applicant" },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("form-input", {
                        attrs: { field: "email", group: "applicant" },
                      }),
                      _vm._v(" "),
                      _c("verify", {
                        attrs: { field: "email", group: "applicant" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("form-input", {
                    attrs: { field: "phone", group: "applicant" },
                  }),
                  _vm._v(" "),
                  _c("form-input", {
                    attrs: { field: "employmentType", group: "applicant" },
                    on: {
                      input: function ($event) {
                        return _vm.prefillEmployment("applicant")
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "zg-transition-block",
                {
                  on: {
                    "after-transition": function ($event) {
                      return _vm.visibleEmploymentFields("applicant")
                    },
                  },
                },
                [
                  _vm.showEmploymentFields("applicant")
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "input-box input-box--with-arrow input-box--with-arrow-right",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c("form-input", {
                                attrs: {
                                  field: "monthlyIncome",
                                  group: "applicant",
                                },
                              }),
                              _vm._v(" "),
                              _c("form-input", {
                                attrs: {
                                  field: "monthlyNetIncome",
                                  group: "applicant",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c("form-input", {
                                attrs: {
                                  field: "otherIncomeAmount",
                                  group: "applicant",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "zg-transition-fade",
                            { on: { "after-transition": _vm.validate } },
                            [
                              _vm.hasEmployment("applicant")
                                ? _c(
                                    "div",
                                    { staticClass: "row" },
                                    [
                                      _c("form-input", {
                                        attrs: {
                                          field: "employer",
                                          group: "applicant",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("form-input", {
                                        attrs: {
                                          field: "employerIndustry",
                                          group: "applicant",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "zg-transition-fade",
                            { on: { "after-transition": _vm.validate } },
                            [
                              _vm.hasEmployment("applicant")
                                ? _c(
                                    "div",
                                    { staticClass: "row" },
                                    [
                                      _c("form-input", {
                                        attrs: {
                                          field: "socialEconomicStatus",
                                          group: "applicant",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("form-input", {
                                        attrs: {
                                          field: "profession",
                                          group: "applicant",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c("form-input", {
                                attrs: {
                                  field: "employedSince",
                                  group: "applicant",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "zg-transition-fade",
                                { on: { "after-transition": _vm.validate } },
                                [
                                  _vm.monthsEmployedLessThanTwelve(
                                    _vm.employedSince
                                  )
                                    ? _c("form-input", {
                                        attrs: {
                                          field: "previousEmployer",
                                          group: "applicant",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "zg-transition-block",
                            { on: { "after-transition": _vm.validate } },
                            [
                              _vm.monthsEmployedLessThanTwelve(
                                _vm.employedSince
                              )
                                ? _c(
                                    "div",
                                    { staticClass: "row" },
                                    [
                                      _c("form-input", {
                                        attrs: {
                                          field: "previousEmployedSince",
                                          group: "applicant",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("form-input", {
                                        attrs: {
                                          field: "previousEmployedTo",
                                          group: "applicant",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "zg-transition-fade",
                            { on: { "after-transition": _vm.validate } },
                            [
                              _vm.timeBasedEmployment("applicant")
                                ? _c(
                                    "div",
                                    { staticClass: "row" },
                                    [
                                      _c("form-input", {
                                        attrs: {
                                          field: "employedTo",
                                          group: "applicant",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("form-input", {
                    attrs: { field: "residenceType", group: "applicant" },
                  }),
                  _vm._v(" "),
                  _c("form-input", {
                    attrs: { field: "rent", group: "applicant" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "zg-transition-fade",
                {
                  on: {
                    "after-transition": function ($event) {
                      return _vm.visibleResidenceFields("applicant")
                    },
                  },
                },
                [
                  _vm.showResidenceFields("applicant")
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "input-box input-box--with-arrow input-box--with-arrow-left",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "row" },
                            [
                              _c("form-input", {
                                staticClass: "align-self-bottom",
                                attrs: {
                                  field: "residenceOwnershipType",
                                  group: "applicant",
                                },
                              }),
                              _vm._v(" "),
                              _c("form-input", {
                                attrs: {
                                  field: "livedSince",
                                  group: "applicant",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "zg-transition-fade",
                            { on: { "after-transition": _vm.validate } },
                            [
                              _vm.ownLiving("applicant")
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "row row--full-width" },
                                        [
                                          _c("form-input", {
                                            attrs: {
                                              field: "hasMortgage",
                                              group: "applicant",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "zg-transition-fade",
                                        {
                                          on: {
                                            "after-transition": _vm.validate,
                                          },
                                        },
                                        [
                                          _vm.hasMortgage("applicant")
                                            ? _c("div", [
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c("form-input", {
                                                      attrs: {
                                                        field: "mortgageBank",
                                                        group: "applicant",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "zg-transition-fade",
                                                      {
                                                        on: {
                                                          "after-transition":
                                                            _vm.validate,
                                                        },
                                                      },
                                                      [
                                                        _vm.livesWithCoApplicant
                                                          ? _c("form-input", {
                                                              attrs: {
                                                                field:
                                                                  "mortgageHolder",
                                                                group:
                                                                  "applicant",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "row" },
                                                  [
                                                    _c("form-input", {
                                                      attrs: {
                                                        field: "mortgageAmount",
                                                        group: "applicant",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("form-input", {
                                                      attrs: {
                                                        field:
                                                          "mortgageMonthlyCost",
                                                        group: "applicant",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row" },
                [
                  _c("form-input", {
                    attrs: {
                      field: "regularMonthlyExpenses",
                      group: "applicant",
                    },
                  }),
                  _vm._v(" "),
                  _c("form-input", {
                    attrs: { field: "loanPurpose", group: "global" },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "row row--full-width" },
                [
                  _c("form-input", {
                    attrs: { field: "pep", group: "applicant" },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.getApplicationEvents.createApplicationWithCoApplicant &&
      !_vm.isAddCoApplicantLead
        ? _c("div", [_c("add-co-applicant-button-variation")], 1)
        : _vm._e(),
      _vm._v(" "),
      _c("zg-transition-block", { on: { "after-transition": _vm.validate } }, [
        _vm.hasCoApplicant || _vm.isAddCoApplicantLead
          ? _c(
              "div",
              [
                !_vm.isAddCoApplicantLead
                  ? _c(
                      "div",
                      { staticClass: "row row--full-width" },
                      [
                        _c("form-input", {
                          attrs: {
                            field: "livesWithCoApplicant",
                            group: "applicant",
                          },
                          on: { input: _vm.updateMaritalStatus },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("form-input", {
                      attrs: { field: "ssn", group: "coApplicant" },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("form-input", {
                          attrs: { field: "email", group: "coApplicant" },
                        }),
                        _vm._v(" "),
                        _c("verify", {
                          attrs: { field: "email", group: "coApplicant" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("form-input", {
                      attrs: { field: "phone", group: "coApplicant" },
                    }),
                    _vm._v(" "),
                    _c("form-input", {
                      attrs: { field: "employmentType", group: "coApplicant" },
                      on: {
                        input: function ($event) {
                          return _vm.prefillEmployment("coApplicant")
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "zg-transition-block",
                  {
                    on: {
                      "after-transition": function ($event) {
                        return _vm.visibleEmploymentFields("coApplicant")
                      },
                    },
                  },
                  [
                    _vm.showEmploymentFields("coApplicant")
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "input-box input-box--with-arrow input-box--with-arrow-right",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("form-input", {
                                  attrs: {
                                    field: "monthlyIncome",
                                    group: "coApplicant",
                                  },
                                }),
                                _vm._v(" "),
                                _c("form-input", {
                                  attrs: {
                                    field: "monthlyNetIncome",
                                    group: "coApplicant",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("form-input", {
                                  attrs: {
                                    field: "otherIncomeAmount",
                                    group: "coApplicant",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "zg-transition-fade",
                              { on: { "after-transition": _vm.validate } },
                              [
                                _vm.hasEmployment("coApplicant")
                                  ? _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c("form-input", {
                                          attrs: {
                                            field: "employer",
                                            group: "coApplicant",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("form-input", {
                                          attrs: {
                                            field: "employerIndustry",
                                            group: "coApplicant",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "zg-transition-fade",
                              { on: { "after-transition": _vm.validate } },
                              [
                                _vm.hasEmployment("coApplicant")
                                  ? _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c("form-input", {
                                          attrs: {
                                            field: "socialEconomicStatus",
                                            group: "coApplicant",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("form-input", {
                                          attrs: {
                                            field: "profession",
                                            group: "coApplicant",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("form-input", {
                                  attrs: {
                                    field: "employedSince",
                                    group: "coApplicant",
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "zg-transition-fade",
                                  { on: { "after-transition": _vm.validate } },
                                  [
                                    _vm.monthsEmployedLessThanTwelve(
                                      _vm.hasCoApplicant
                                        ? _vm.coEmployedSince
                                        : _vm.employedSince
                                    )
                                      ? _c("form-input", {
                                          attrs: {
                                            field: "previousEmployer",
                                            group: "coApplicant",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "zg-transition-block",
                              { on: { "after-transition": _vm.validate } },
                              [
                                _vm.monthsEmployedLessThanTwelve(
                                  _vm.hasCoApplicant
                                    ? _vm.coEmployedSince
                                    : _vm.employedSince
                                )
                                  ? _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c("form-input", {
                                          attrs: {
                                            field: "previousEmployedSince",
                                            group: "coApplicant",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("form-input", {
                                          attrs: {
                                            field: "previousEmployedTo",
                                            group: "coApplicant",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "zg-transition-fade",
                              { on: { "after-transition": _vm.validate } },
                              [
                                _vm.timeBasedEmployment("coApplicant")
                                  ? _c(
                                      "div",
                                      { staticClass: "row" },
                                      [
                                        _c("form-input", {
                                          attrs: {
                                            field: "employedTo",
                                            group: "coApplicant",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "zg-transition-fade",
                  { on: { "after-transition": _vm.validate } },
                  [
                    !_vm.livesWithCoApplicant
                      ? _c(
                          "div",
                          { staticClass: "row" },
                          [
                            _c("form-input", {
                              attrs: {
                                field: "residenceType",
                                group: "coApplicant",
                              },
                            }),
                            _vm._v(" "),
                            _c("form-input", {
                              attrs: { field: "rent", group: "coApplicant" },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "zg-transition-fade",
                  {
                    on: {
                      "after-transition": function ($event) {
                        return _vm.visibleResidenceFields("coApplicant")
                      },
                    },
                  },
                  [
                    _vm.showResidenceFields("coApplicant") &&
                    !_vm.livesWithCoApplicant
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "input-box input-box--with-arrow input-box--with-arrow-left",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c("form-input", {
                                  attrs: {
                                    field: "residenceOwnershipType",
                                    group: "coApplicant",
                                  },
                                }),
                                _vm._v(" "),
                                _c("form-input", {
                                  attrs: {
                                    field: "livedSince",
                                    group: "coApplicant",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "zg-transition-fade",
                              { on: { "after-transition": _vm.validate } },
                              [
                                _vm.ownLiving("coApplicant")
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "row row--full-width",
                                          },
                                          [
                                            _c("form-input", {
                                              attrs: {
                                                field: "hasMortgage",
                                                group: "coApplicant",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "zg-transition-fade",
                                          {
                                            on: {
                                              "after-transition": _vm.validate,
                                            },
                                          },
                                          [
                                            _vm.hasMortgage("coApplicant")
                                              ? _c("div", [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c("form-input", {
                                                        attrs: {
                                                          field: "mortgageBank",
                                                          group: "coApplicant",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "zg-transition-fade",
                                                        {
                                                          on: {
                                                            "after-transition":
                                                              _vm.validate,
                                                          },
                                                        },
                                                        [
                                                          _vm.coApplicantHasPartner
                                                            ? _c("form-input", {
                                                                attrs: {
                                                                  field:
                                                                    "mortgageHolder",
                                                                  group:
                                                                    "coApplicant",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c("form-input", {
                                                        attrs: {
                                                          field:
                                                            "mortgageAmount",
                                                          group: "coApplicant",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("form-input", {
                                                        attrs: {
                                                          field:
                                                            "mortgageMonthlyCost",
                                                          group: "coApplicant",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("form-input", {
                      attrs: {
                        field: "regularMonthlyExpenses",
                        group: "coApplicant",
                      },
                    }),
                    _vm._v(" "),
                    _vm.livesWithCoApplicant
                      ? _c("form-input", {
                          attrs: { field: "rent", group: "coApplicant" },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row row--full-width" },
                  [
                    _c("form-input", {
                      attrs: { field: "pep", group: "coApplicant" },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("zg-transition-block", { on: { "after-transition": _vm.validate } }, [
        !_vm.getApplicationEvents.createApplication && !_vm.isAddCoApplicantLead
          ? _c(
              "div",
              { staticClass: "row row--full-width" },
              [
                _c("form-input", {
                  attrs: { field: "digitalCommunication", group: "applicant" },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("zg-transition-fade", { on: { "after-transition": _vm.validate } }, [
        _vm.isAddCoApplicantLead
          ? _c(
              "div",
              { staticClass: "row row--full-width" },
              [
                _c("form-input", {
                  attrs: {
                    field: "digitalCommunication",
                    group: "coApplicant",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("zg-transition-block", { on: { "after-transition": _vm.validate } }, [
        !_vm.getApplicationEvents.createApplication && !_vm.isAddCoApplicantLead
          ? _c(
              "div",
              { staticClass: "row row--full-width" },
              [
                _c("form-input", {
                  attrs: { field: "userAgreement", group: "applicant" },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.isAddCoApplicantLead
        ? _c(
            "div",
            { staticClass: "row row--full-width" },
            [
              _c("form-input", {
                attrs: { field: "userAgreement", group: "coApplicant" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }