var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.name
    ? _c(
        "div",
        {
          class: [
            "notice",
            {
              [`notice--${_vm.name}`]: _vm.name,
            },
          ],
        },
        [
          _c(
            "zg-messaging-inline-text",
            {
              attrs: {
                background: _vm.onBeigeBackground ? "white" : "beige",
                type: _vm.type,
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }