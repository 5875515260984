<template>
  <div
    v-if="name"
    :class="['notice', {
      [`notice--${name}`]: name
    }]"
  >
    <zg-messaging-inline-text
      :background="onBeigeBackground ? 'white': 'beige'"
      :type="type"
    >
      {{ text }}
    </zg-messaging-inline-text>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Notice',
    components: {
      ZgMessagingInlineText: () => import(/* webpackChunkName: 'zc/zg-messaging-inline-text' */
        '@zc/components/ZgMessagingInlineText/ZgMessagingInlineText')
    },
    props: {
      name: {
        type: String,
        required: true,
        default: null
      },
      type: {
        type: String,
        required: false,
        default: 'info'
      },
      onBeigeBackground: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    computed: {
      ...mapGetters('content', ['getContent']),
      text () {
        return this.getContent?.elements?.notices?.[this.name]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .notice {
    margin: 0 0 rhythm();
  }

  .notice--prefill {
    margin: rhythm(medium) 0 rhythm();
  }
</style>
